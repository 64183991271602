import * as React from "react"
import { useState, useEffect } from "react"
import '../styles/index.css'
import { Helmet } from "react-helmet"
import MailchimpSubscribe from "react-mailchimp-subscribe";
import SimpleForm from "../components/SimpleForm";
import { graphql } from "gatsby";


// markup
const IndexPage = ({ data }) => {

  const [audio, setAudio] = useState(null)

  useEffect(() => { setAudio(new Audio("shotssollyy.mp3")) }, [])

  function playAudio() {
    audio?.play();
    console.log("poo")
  }

  return (
    <div className="container">
      <Helmet>
        <title>SOLLYY</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main>
        <h1 className="title">SOLLYY</h1>
        <h2 className="subtitle">sydney's best kept secret</h2>
        <div className='logo' id='spinner' onClick={() => { audio?.play() }}>
        </div>
        <h3 className='links'>


          {data.allContentfulFrontPageLinks.nodes.map(node => (
            <span>
              <a href={node.link} target="_blank">{node.caption}</a><br /><br />
            </span>
          ))}
          <a href="/live">TAP IN LIVE</a>
        </h3>
        <p className="socials">
          <a href="https://www.instagram.com/sollyywood/" target="_blank">instagram</a><span>       </span>
          <a href="https://soundcloud.com/sollyywood" target="_blank">soundcloud</a><span>       </span>
          <a href="https://sollyywood.bandcamp.com/" target="_blank">bandcamp</a><span>       </span>
          <a href="https://twitter.com/sollyywood" target="_blank">twitter</a>
          <br /><br /><a href="mailto:tappedin@sollyy.com">tappedin@sollyy.com</a><br />
        </p>
        <div className='form'>
          <h2 className="tap-in-label">tap in</h2>
          <MailchimpSubscribe
            url="https://sollyy.us17.list-manage.com/subscribe/post?u=993174a0aec5274d1745af493&amp;id=5e3839a0a5"
            render={({ subscribe, status, message }) => (
              <SimpleForm
                status={status}
                message={message}
                onSubmitted={formData => subscribe(formData)}
              />
            )}
          />
        </div>
      </main>
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
query FrontPageLinksQuery {
  allContentfulFrontPageLinks(sort: {order: DESC, fields: createdAt}) {
    nodes {
      link
      caption
    }
  }
}
`
