import React from 'react';

export const SimpleForm = ({ status, message, className, style, onSubmitted }) => {
    let input;
    const submit = () =>
        input &&
        input.value.indexOf("@") > -1 &&
        onSubmitted({
            EMAIL: input.value
        });

    return (
        <div className={className} style={style}>
            <input
                ref={node => (input = node)}
                type="email"
                placeholder="email"
            />
            <button onClick={submit}>confirm</button>
            {status === "sending" && <h3>sending...</h3>}
            {status === "error" && (
                <h3
                    style={{ color: "red" }}>invalid</h3>
            )}
            {status === "success" && (
                <h3
                    style={{ color: "black" }}>you're tapped in!</h3>
            )}
        </div>
    );
};

export default SimpleForm;